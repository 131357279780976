import React, { Fragment, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/List';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import './style.css';

const menus = [
  {
    id: 1,
    title: 'Ana Sayfa',
    link: '/home',
  },

  {
    id: 3,
    title: 'Hakkımızda',
    link: '/about',
  },

  {
    id: 6,
    title: 'Hizmetlerimiz',
    link: '/practice',
  },

  {
    id: 5,
    title: 'Blog',
    link: '/',
  },
  {
    id: 88,
    title: 'İletişim',
    link: '/contact',
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="mobail_menu">
      <div className={`mobileMenu ${menuActive ? 'show' : ''}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem
                className={item.id === openId ? 'active' : null}
                key={mn}
              >
                <Link className="active" to={item.link}>
                  {item.title}
                </Link>
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
