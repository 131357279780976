import React, { useState } from 'react';

import { Link } from 'react-router-dom';

const About2 = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <section className="pt-[120px] pb-[100px] md:py-[80px]">
      <div className="wraper">
        <div className="grid grid-cols-20 items-center gap-3">
          <div className="col-span-6 md:col-span-12">
            <div className="mb-[20px]">
              <div className="text-left sm:text-center">
                <span className="text-[16px] text-[#ada282]">
                  Alanımızda Uzmanız
                </span>
                <h2
                  className="text-[36px] md:text-[26px] font-medium text-[#333] pb-[20px] relative 
                        before:absolute before:content-[''] before:left-[-68px] 
                        before:top-[30px] before:transform before:-translate-y-1/2 
                        before:w-[60px] before:h-[1px] before:bg-[#c0b596] md:before:hidden"
                >
                  Biz Kimiz?
                </h2>
              </div>
              <p className="text-[#666] text-[15px] leading-[28px] mb-[30px]">
                Mali Müşavirlik sektöründe uzmanlaşmış bir ekibiz. Profesyonel
                deneyimimiz ve bilgi birikimimizle, müşterilerimize kapsamlı
                mali danışmanlık hizmetleri sunuyoruz. Müşteri memnuniyeti ve
                güveni bizim için önceliktir. Ekibimiz, her müşterinin
                ihtiyaçlarını anlamak ve en uygun çözümleri sunmak için sürekli
                olarak çalışmaktadır.
              </p>
              <p className="text-[#666] text-[15px] leading-[28px] mb-[30px]">
                {' '}
                Amacımız, müşterilerimizin mali hedeflerini başarıyla
                gerçekleştirmelerine yardımcı olmaktır.
              </p>
              <p className="text-[#666] text-[15px] leading-[28px] mb-[30px]">
                {' '}
                <h4 className="text-[18px]">Kuruluşumuz</h4>
                2024 yılında Serbest muhasebeci Mali Müşavir Veli YILDIRIM
                tarafından kurulan ofisimiz, işletmelerin finansal hedeflerine
                ulaşmalarına yardımcı olmak amacıyla kurulmuştur. Deneyimli mali
                müşavirlerden oluşan ekibimiz, geniş bir sektör yelpazesine
                hizmet vermektedir ve her bir müşterinin benzersiz ihtiyaçlarına
                odaklanarak çözümler sunmaktadır.
              </p>
              <p className="text-[#666] text-[15px] leading-[28px] mb-[30px]">
                {' '}
                <h4 className="text-[18px]">Misyonumuz</h4>
                Misyonumuz, müşterilerimizin finansal başarılarını desteklemek
                ve güvenilir danışmanlık hizmetleriyle işletmelerini daha
                ileriye taşımaktır. Her aşamada müşteri memnuniyetini ön planda
                tutarak, şeffaf, etik ve profesyonel bir yaklaşımla çalışırız.
              </p>
              <p className="text-[#666] text-[15px] leading-[28px] mb-[30px]">
                {' '}
                <h4 className="text-[18px]">Vizyonumuz</h4>
                Vizyonumuz, müşterilerimizin güvenilir bir iş ortağı olmak ve
                finansal hedeflerine ulaşmalarını sağlamaktır. Sürekli olarak
                gelişen ve yenilikçi yaklaşımlarla, mali müşavirlik sektöründe
                öncü bir konumda yer almayı hedefliyoruz.
              </p>

              <div className="mb-[50px] col:mb-[20px]">
                <Link
                  to="/about"
                  className="bg-[#c0b596] cursor-pointer text-[16px] font-semibold text-white px-[38px] py-[10px]  capitalize inline-block mt-[6px] transition ease-in-out duration-300 hover:bg-[#d4c291]
                        col:mb-[5px] col:mt-[15px] col:text-[15px] col:px-[18px] col:py-[8px] 
                        "
                >
                  Hakkımızda Daha Fazlası..
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About2;
